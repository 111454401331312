import React, { useEffect,useState } from "react";
import { useLocation } from 'react-router-dom'
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import CopyRight from "../../../components/footer/CopyRight";
import BlogDetailsForm from "../../../components/form/BlogDetailsForm";
import axios from "axios";

const BlogDetails = () => {
  //var baseUrl = "http://localhost:9010/api/cms";
  var baseUrl = process.env.REACT_APP_CMS_API;
  const search = useLocation().search
  const searchParams = new URLSearchParams(search)
  const [blog,setBlog] = useState({});
  useEffect(() => {
    axios.get(baseUrl+"/blogs").then((res) => {
      var x = res.data.result.find(a=>a.id === searchParams.get("id")*1);
      setBlog(x?x:{});
    });
  }, []);
  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>eKYC | Chia sẻ kiến thức</title>
      </Helmet>
      {/* End Page SEO Content */}

      <Header />
      {/* End Header */}

      {/* =============================================
            Fancy Hero Two
        ==============================================  */}
      <div className="fancy-hero-five bg-white">
        <img
          src="images/shape/95.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="images/shape/96.svg"
          alt="shape"
          className="shapes shape-two"
        />
        <div className="bg-wrapper ">
          <div className="container">
            <div className="col-lg-10 m-auto text-center">
              <h6 className="page-title font-gilroy-bold">Chia sẻ kiến thức</h6>
              <h1 className="heading font-gilroy-bold">
                {blog.title}
              </h1>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper */}
      </div>
      {/* /.fancy-hero-two */}

      {/* =====================================================
            Feature Blog One
        ===================================================== */}
      <div className="blog-page-bg">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="container">
          <div className="row">
            <div className="col-xl-10 offset-xl-1 col-lg-12 feature-blog-one width-lg blog-details-post-v1">
              <div className="post-meta">
                <img
                  src={blog.image}
                  alt="media post"
                  className="image-meta"
                />
                <div className="tag">{blog.publish_date}</div>
                <div className="cms-content" dangerouslySetInnerHTML={{__html:blog.content}}/>
              </div>
              {/* /End post content  */}

              {/* /.comment-form-section */}
            </div>
            {/* End .col */}
          </div>
          {/* End .row */}
        </div>
      </div>
      {/* /.feature-blog-one */}

      <footer className="theme-footer-one pt-130 md-pt-70">
        <div className="top-footer">
          <div className="container">
            <Footer />
          </div>
          {/* /.container */}
        </div>
        {/* /.top-footer */}

        <div className="container">
          <div className="bottom-footer-content">
            <CopyRight />
          </div>
          {/*  /.bottom-footer */}
        </div>
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default BlogDetails;
