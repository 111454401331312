import React, { useEffect,useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const BlogStyle1 = () => {
  //var baseUrl = "http://localhost:9010/api/cms";
  var baseUrl = process.env.REACT_APP_CMS_API;
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    axios.get(baseUrl+"/blogs").then((res) => {
      console.log(res);
      setBlogs(res.data.result);
    });
  }, []);
  return (
    <>
      {blogs.map((item, i) => (
        <div
          className="col-lg-4 col-md-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          key={i}
        >
          <div className="post-meta">
            <img
              src={item.image}
              alt="media"
              className="image-meta"
            />
            <h3>
              <Link to={"/kien-thuc-chi-tiet?id="+item.id} className="title font-gilroy-bold">
                {item.title}
              </Link>
            </h3>
            <Link
              to={item.routePath}
              className="read-more d-flex justify-content-between align-items-center"
            >
              <span>Đọc thêm</span>
              <i className="flaticon-right-arrow"></i>
            </Link>
          </div>
          {/* /.post-meta */}
        </div>
      ))}
    </>
  );
};

export default BlogStyle1;
