import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="row justify-content-end">
      <div
        className="col-lg-3 col-md-12 footer-about-widget"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <a href="index.html" className="logo">
          <img src="images/logo/deski_02.svg" alt="" />
        </a>
      </div>
      {/* /.about-widget */}
      <div
        className="col-lg-5 col-md-4 footer-list"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="50"
      >
        <h5 className="title">Truy cập nhanh</h5>
        <ul className="footer-list">
          <li>
            <Link to="/">Trang chủ</Link>
          </li>
          <li>
            <Link to="/tinh-nang">Tính năng</Link>
          </li>
          <li>
            <Link to="/bang-gia">Báo giá</Link>
          </li>
          <li>
            <Link to="/kien-thuc">Chia sẻ kiến thức</Link>
          </li>
          <li>
            <Link to="/contact-cs">Liên hệ</Link>
          </li>
          
        </ul>
      </div>
      {/* /.footer-list */}
      <div
        className="col-lg-3 col-md-4 footer-list"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="100"
      >
        <h5 className="footer-title">Demo</h5>
        <ul>
          <li>
            <Link to="/about-cs">eKYC</Link>
          </li>
          <li>
            <Link to="/faq-details">OCR</Link>
          </li>
          <li>
            <Link to="/team-6">Old Photo Restore</Link>
          </li>
        </ul>
      </div>
      {/* /.footer-list */}
      <div
        className="col-lg-3 col-md-4 address-list"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="150"
      >
        <h5 className="footer-title">Liên hệ</h5>
        <ul>
          <li>
            <a href="#">hello@ekyc.solutions</a>
          </li>
        </ul>
      </div>
      {/* /.footer-list */}
    </div>
    //.row
  );
};

export default Footer;
