import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const FaqContent = [
  {
    title: "Thẻ CCCD gắn chip là gì?",
    desc: `Thẻ CCCD gắn chíp là thiết bị nhận dạng thông minh, cho phép tích hợp số lượng lớn dữ liệu về bằng lái xe, bảo hiểm, hộ khẩu,... Nó đóng vai trò như một thiết bị nhận diện, xác định danh tính và là “chìa khóa” giúp truy cập thông tin của công dân trong hệ thống cơ sở dữ liệu quốc gia. Người dân chỉ cần sử dụng thẻ căn cước điện tử này là có thể tiếp cận được với nhiều dịch vụ vốn đòi hỏi nhiều loại giấy tờ khác nhau.`,
    expand: "a",
  },
  {
    title: "Vì sao nên lựa chọn giải pháp eKYC?",
    desc: `eKYC có khả năng đọc phần lớn những thông tin trong các trường dữ liệu được công khai trên thẻ căn cước công dân mới này. Những thông tin này đã được xác thực bởi Bộ Công An nên các tổ chức, doanh nghiệp hoàn toàn có thể yên tâm sử dụng.`,
    expand: "b",
  },
  {
    title: "Lợi ích khi sử dụng giải pháp trích xuất thông tin thẻ CCCD gắn chip",
    desc: `Đọc và xác thực thông tin trên thẻ căn cước công dân chính xác, nhanh chóng và tiện lợi. Quy trình bóc tách thông tin trên thẻ được thực hiện một cách tự động hóa , phục vụ cho quy trình cập nhật thông tin. Nhận diện thẻ căn cước không hợp lệ. Giảm thiểu chi phí thủ công.`,
    expand: "c",
  },
  {
    title: "Những đơn vị nào nên sử dụng eKYC?",
    desc: `Giải pháp quét thông tin CCCD SDK thích hợp sử dụng cho nhiều doanh nghiệp, tổ chức có nhu cầu nâng cao trải nghiệm người dùng thông qua các hoạt động như: tự động hóa đọc thông tin trên thẻ căn cước công dân, cần xác thực, định danh người dùng hoặc truy xuất thông tin. Giải pháp này phù hợp sử dụng với các đơn vị như: cơ quan Chính Phủ, hệ thống ngân hàng, các tổ chức tài chính, giao thông,... `,
    expand: "d",
  },
];

const FaqClassic = () => {
  return (
    <div className="accordion-style-four">
      <div className="faq-wrraper">
        <Accordion preExpanded={["b"]} allowZeroExpanded>
          {FaqContent.map((item, i) => (
            <AccordionItem className="card" key={i} uuid={item.expand}>
              <AccordionItemHeading className="card-header">
                <AccordionItemButton>
                  <h5 className="mb-0">
                    <button className="btn btn-link">{item.title}</button>{" "}
                  </h5>
                </AccordionItemButton>
              </AccordionItemHeading>
              {/* Accordion Heading */}
              <AccordionItemPanel className="card-body fadeInUp">
                <p>{item.desc}</p>
              </AccordionItemPanel>
              {/* Accordion Body Content */}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default FaqClassic;
