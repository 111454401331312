import React from "react";
import { Helmet } from "react-helmet";
import BillingDetails from "../../../components/e-commerce/checkout/BillingDetails";
import LogIn from "../../../components/e-commerce/checkout/LogIn";
import Payment from "../../../components/e-commerce/checkout/Payment";
import PromoCode from "../../../components/e-commerce/checkout/PromoCode";
import Header from "../../../components/header/HeaderDemo";
import HeaderLandingWebsiteBuilder from "../../../components/header/landing/HeaderLandingWebsiteBuilder";
import axios from "axios"
import { toast } from 'react-toastify';
import Select from 'react-select';

const DemoOCR = () => {

  const options = [
    { value: 'liveness', label: 'Xác định ảnh giả mạo' },
    { value: 'front', label: 'Mặt trước CMND/CCCD/Hộ chiếu' },
    { value: 'back', label: 'Mặt sau CMND/CCCD' },
    { value: 'ocr', label: 'Hai mặt CMND/CCCD/Hộ chiếu' },
    { value: 'ekyc', label: 'Bóc tách thông tin và so sánh ảnh chân dung' },
  ]

  const labels = {
    idnumber: "Số CMND/CCCD/Hộ chiếu",
    name: "Họ tên",
    dob: "Ngày sinh",
    gender: "Giới tính",
    card_type: "Loại giấy tờ",
    issue_date: "Ngày cấp",
    issued_place: "Nơi cấp",
    address: "Địa chỉ",
    expired_date: "Ngày hết hạn",
    nationality: "Quốc tịch",
    place_of_origin: "Nguyên quán",
    face_match: "Ảnh chân dung khớp?",
    face_score: "Độ chính xác ảnh chân dung",
    spoof_class: "Thật/Giả?",
    spoof_score: "Độ chính xác xác định thật giả",
  }

  const labels_2 = {
    spoof_class: "Thật/Giả?",
    spoof_score: "Độ chính xác xác định thật giả",
  }

  const labels_1 = {
    idnumber: "Số CMND/CCCD/Hộ chiếu",
    name: "Họ tên",
    dob: "Ngày sinh",
    gender: "Giới tính",
    card_type: "Loại giấy tờ",
    issue_date: "Ngày cấp",
    issued_place: "Nơi cấp",
    address: "Địa chỉ",
    expired_date: "Ngày hết hạn",
    nationality: "Quốc tịch",
    place_of_origin: "Nguyên quán"
  }

  const [serivce, setService] = React.useState(options[1])
  const [frontImg, setFrontImg] = React.useState(null)
  const [backImg, setBackImg] = React.useState(null)
  const [faceImg, setFaceImg] = React.useState(null)

  const frontRef = React.createRef()
  const backRef = React.createRef()
  const faceRef = React.createRef()

  const [result, setResult] = React.useState(null)
  const [text, setText] = React.useState('Bóc tách thông tin')

  /*Hàm nhận file và chuyển thành base64*/
  const getBase64 = (file,file_name) => {
    return new Promise((resolve,reject) => {
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
        if (file_name=='front') setFrontImg(baseURL)
        if (file_name=='back') setBackImg(baseURL)
        if (file_name=='face') setFaceImg(baseURL)
      };
    });
  }

  const actionEKYC = async () => {
    setResult(null)
    /*Kiểm tra dữ liệu front, back, face theo từng service, phải not null*/
    if (frontImg==null) {
      alert('Chưa chọn ảnh cần bóc tách')
      return
    }
    /*Trường hợp front, bỏ data:image/*;base64*/
    var frontImg_ = frontImg.split(',')[1]
    setText('Đang thực hiện...')
    /*Gửi lên server*/
    axios.post('https://ocr-api-uat.ekyc.solutions/api/ekyc/demo/v1/doc-ocr',{
      image_base64: frontImg_
    }).then(res=>{
      console.log(res)
      if (res.data.code==200){
        toast.info('Bóc tác dữ liệu thành công', {
          position: 'bottom-left',
        })
      }else{
        toast.info(res.data.message, {
          position: 'bottom-left',
        })
      }
      setText('Bóc tách thông tin')
      setResult(res.data.result)
    }).catch(err=>{
      console.log(err)
      setText('Bóc tách thông tin')
      toast.info('Bóc tác dữ liệu thất bại, vui lòng thử lại', {
        position: 'bottom-left',
      })
    })
  }

  return (
    
    <div className="main-page-wrapper p0 font-gordita">
      <Helmet>
        <title>eKYC | Định danh khách hàng trực tuyến</title>
      </Helmet>
      {/* End Page SEO Content */}

      {/* <!-- 
    =============================================
    Theme Main Menu
    ============================================== 
    --> */}
      <Header />

      {/* <!-- 
    =============================================
    Checkout Page
    ============================================== 
    --> */}

      <div className="checkout-section pt-100 pb-100 lg-pt-180 sm-pb-50">
        <div className="row pt-50 pb-50 mb-50 mt-20" style={{background:'#fff7ef'}}>
            <div className="col-xl-12 col-lg-12">
              <h2 className="heading text-center">Nhận diện ký tự</h2>
              <p className="text-center mt-2">Lưu ý: đây là mô hình tổng quát, với các dạng tài liệu riêng phải qua quy trình huấn luyện để có độ chính xác cao</p>
            </div>
					</div>
        <div className="container">
          
          {/* <!-- /.checkout-toggle-area --> */}
          
          <div className="checkout-form">
            <div className="row">
              <div className="col-xl-4 border rounded col-lg-4">
                <h5 className="py-4">Chọn ảnh cần bóc tách chữ</h5>
                  <form>
                    <div className="row">
                      <div className="form-group">
                          <input
                            type="file"
                            name="front"
                            onChange={(e)=>getBase64(e.target.files[0],'front')}
                            ref={frontRef}
                            placeholder="Ảnh cần bóc tách chữ"
                            required
                          />
                        </div>
                      </div>
                    <div className="tp-login-button mt-50">
                      <button onClick={actionEKYC} className="bg-black text-white w-100 mb-20 rounded py-2 font-weight-bold" type="button">{text}</button>
                    </div>
                    
                  </form>
              </div>
              {/* <!-- /.col- --> */}

              <div className="col-xxl-8 col-lg-8 ms-auto">
              {result && result!='' && <div className="row align-items-center">
                <div className="col-lg-12 col-md-12 ekyc">
                <div 
                    className="project-start-content"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    data-aos-delay="400"
                  >
                    <div className="col-lg-12 col-md-12 card p-4">
                      <div className="row">
                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                        <h5 className="sub-title mb-3">Kết quả bóc tách</h5>
                          <div className="row">
                            <img src={"data:image/png;base64,"+result}/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>}
              </div>
            </div>
            {/* <!-- /.row --> */}
          </div>
          {/* <!-- /.checkout-form --> */}
        </div>
        {/* <!-- /.container --> */}
      </div>

      {/* <!-- /.checkou-section --> */}
    </div>
  );
};

export default DemoOCR;
