import React from "react";
import { Link } from "react-router-dom";
import FormFooterSignup from "../form/FormFooterSignup";

const FooterSeven = () => {
  return (
    <div className="row ">
      <div
        className="col-xl-3 col-lg-2 mb-40"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="logo">
          <a href="index.html">
            <img style={{width:170}} src="images/logo/deski_06.svg" alt="logo" />
          </a>
        </div>
      </div>
      {/* End .col */}

      <div
        className="col-lg-5 col-md-6 mb-40"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="100"
      >
        <h5 className="title">Truy cập nhanh</h5>
        <ul className="footer-list">
          <li>
            <Link to="/">Trang chủ</Link>
          </li>
          <li>
            <Link to="/tinh-nang">Tính năng</Link>
          </li>
          <li>
            <Link to="/bang-gia">Báo giá</Link>
          </li>
          <li>
            <Link to="/kien-thuc">Chia sẻ kiến thức</Link>
          </li>
          <li>
            <Link to="/contact-cs">Liên hệ</Link>
          </li>
          
        </ul>
      </div>

      
      <div
        className="col-xl-4 col-lg-5 mb-40"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="200"
      >
        <div className="newsletter">
          <h5 className="title">Nhận thông báo từ eKYC</h5>
          <p>
            Hơn <span>1,000</span> người đăng ký nhận thông tin từ chúng tôi
          </p>

          <FormFooterSignup />

          <div className="info">
            Chúng tôi chỉ gửi bạn các thông tin liên quan đến eKYC
          </div>
        </div>
        {/* /.newsletter */}
      </div>
    </div>
  );
};

export default FooterSeven;
