import React from "react";

const featuresContent = [
  {
    bgColor: "#F7EDFF",
    icon: "166",
    title: "Quản lý giao dịch",
    descriptions:
      "Cung cấp các công cụ quản lý giao dịch, giúp bạn dễ dàng theo dõi các giao dịch của mình.",
    link: "https://console.ekyc.solutions/",
    dealyAnimation: "",
  },
  {
    bgColor: "#D8FFF1",
    icon: "167",
    title: "Debug API",
    descriptions:
      "Cho phép xem chi tiết các thông tin gửi và nhận từ eKYC qua API.",
    link: "https://console.ekyc.solutions/",
    dealyAnimation: "100",
  },
  {
    bgColor: "#E6FBFF",
    icon: "168",
    title: "Thiết bị kiểm soát vào ra",
    descriptions:
      "Cung cấp các thiết bị kiểm soát vào ra, giúp bạn dễ dàng quản lý thông tin vào ra sử dụng CCCD gắn chip.",
    link: "https://ekyc.solutions/thiet-bi-kiem-soat-vao-ra/",
    dealyAnimation: "",
  },
  {
    bgColor: "#FFEBDB",
    icon: "169",
    title: "Hỗ trợ online",
    descriptions:
      "Hỗ trợ online 24/7, giúp bạn giải đáp các thắc mắc nhanh chóng và hiệu quả.",
    link: "https://oa.zalo.me/vinaphone",
    dealyAnimation: "100",
  },
];

const FancyFeatureThirtyTwo = () => {
  return (
    <div className="row">
      {featuresContent.map((item, i) => (
        <div
          className="col-md-6"
          data-aos="fade-up"
          data-aos-delay={item.dealyAnimation}
          key={i}
        >
          <div className="block-style-thirtyTwo d-flex">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: item.bgColor }}
            >
              <img src={`images/icon/${item.icon}.svg`} alt="icon" />
            </div>
            <div className="text">
              <h4>{item.title}</h4>
              <p>{item.descriptions}</p>
              <a href={item.link} className="theme-btn-ten">
                Learn more
                <i className="fa fa-chevron-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FancyFeatureThirtyTwo;
